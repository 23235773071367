// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.post-wrapper {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  .post {
    display: inline-block;
//    margin-right: 15px;
    margin-bottom: 30px;
    max-width: 350px;
    min-width: 350px;
    .post-title {
      overflow-wrap: anywhere;
      margin-bottom: 10px;
    }
  }
}

.header-image {
  margin-bottom: 30px;
}

.home-latest {
  margin-top: 15px;
}

.home-content {
  blockquote {
    margin-bottom: 30px;
    p {
      margin-bottom: 5px;
    }
  }
}
